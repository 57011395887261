<template>
  <div>
      <p class="title text-center">{{ $t('cues_view.title') }}</p>
      <cue-list justify="center"
        :cues="cues"
      />
  </div>
</template>

<script>
import cueList from '@/components/cue'

export default {
  // data: () => ({
    
  // }),
  components: {cueList},
  mounted() {
    this.$store.dispatch('getCues')

  },
  computed: {
    cues() {
      return this.$store.state.cues
    },
  },
}
</script>

<style>

</style>