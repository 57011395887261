<template>
  <div>

    <div>
      <v-data-table
        :headers="cueListHeaders"
        :items="cues"
        :items-per-page="-1"
        class="elevation-1"
        :no-data-text="$t('table_empty')"
        :item-class="setRowStyle"
        :footer-props="{
          pageText: $t('table_footer.page_text'),
          'items-per-page-text': $t('table_footer.items_per_page'),
          'items-per-page-all-text': $t('table_footer.items_per_page_all_text')
        }"
      >
        <template v-slot:top> 
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('cues_page.cues_list') }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <div v-if="isOperator && isLicenseValid">
              <v-btn
                color="primary--text"
                class="mb-2 "
                @click="refresh"
              >
                {{ $t('cues_page.refresh_button') }}
              </v-btn>
              <v-dialog persistent v-model="uploadCueDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mb-2 primary--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('cues_page.upload_cue_button') }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ $t('cues_page.upload_cue_card_title') }}</span>
                  </v-card-title>
      
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-file-input
                        accept=".cue"
                        multiple
                        small-chips
                        show-size
                        v-model="files"
                      ></v-file-input>
                      <v-progress-linear
                        v-show="loading"
                        v-model="uploadProgress"
                        :query="true"
                        :indeterminate="uploadProgress == 100">
                      </v-progress-linear>
                      <p v-if="errors.internal_error">
                        <b>{{ $t('cues_page.internal_error') }}</b>
                      </p>
                      <p v-else-if="errors.out_of_disc_space_error">
                        <b>{{ $t('cues_page.disk_space_error') }}</b>
                      </p>
                      <p v-else-if="errors.file_upload_errors.length">
                        <b>{{ $t('cues_page.file_upload_error') }}:</b>
                        <ul>
                          <li v-for="(error, index) in errors.file_upload_errors" :key="index"><span v-text="error"></span></li>
                        </ul>
                      </p>
                    </v-form>
                      
                  </v-card-text>
      
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="loading" class="mb-2 primary--text" @click="close">
                      {{ $t('cues_page.upload_cue_card_cancel') }}
                    </v-btn>
                    <v-btn :disabled="!isValid" class="mb-2 primary--text" @click="uploadCues">
                      {{ $t('cues_page.upload_cue_card_upload') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="uploadGifDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mb-2 primary--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('cues_page.upload_gifs_button') }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ $t('cues_page.upload_gifs_card_title') }}</span>
                  </v-card-title>
      
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-file-input
                        accept=".gif"
                        multiple
                        small-chips
                        show-size
                        :loading='loading'
                        v-model="files"
                      ></v-file-input>
                      <p v-if="Object.keys(errors.file_upload_errors).length">
                      <b v-for="(error, index) in errors.file_upload_errors.files" :key="index"><span v-text="error"></span></b>
                      </p>
                    </v-form>
                      
                  </v-card-text>
      
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="loading" class="mb-2 primary--text" @click="close">
                      {{ $t('cues_page.upload_cue_card_cancel') }}
                    </v-btn>
                    <v-btn :disabled="!isValid" class="mb-2 primary--text" @click="uploadGifs">
                      {{ $t('cues_page.upload_cue_card_upload') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </div>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="deleteCueFile(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.gif="{ item }">
          <v-icon v-if="item.gif" small @click="openGif(item)">
            mdi-file-image
          </v-icon>
        </template>
        <template v-slot:item.created="{ item }">
          {{ normalizeCreatedTime(item.created) }}
        </template>
      </v-data-table>
      <v-dialog v-model="dialogOpenGif" max-width="200px">
        <v-card>
          <v-img max-width="200" max-height="200"
          :src='getGif(openedGif)'>
          </v-img>
        </v-card>
      </v-dialog>
      <v-dialog 
        v-model="dialogDelete"
        width="unset"
        @keydown.enter.prevent="deleteItemConfirm"
        @keydown.esc.prevent="closeDelete"
      >
        <v-card>
          <v-card-title class="headline">{{ $t('cues_page.delete_cue_card') }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mb-2 primary--text" @click="closeDelete">{{ $t('cues_page.delete_cue_card_cancel') }}</v-btn>
            <v-btn class="mb-2 primary--text" @click="deleteItemConfirm">{{ $t('cues_page.delete_cue_card_ok') }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDeleteError" width="unset">
        <v-card>
          <v-card-title class="headline">
              <p>{{ $t('cues_page.delete_cue_card_title_delete') }} {{cue_name}} {{ $t('cues_page.delete_cue_card_title') }}: </p>
            
          </v-card-title>
          <v-card-text>
            <ul><li v-for="(playlist, key) in errors" :key="key">{{playlist}}</li></ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary--text" @click="closeDeleteError">ok</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
      
</template>





<script>
  import Api from "@/api/api"
  import moment from 'moment';

  export default {
    props: ['cues'],
    data: () => ({
      uploadCueDialog: false,
      uploadGifDialog: false,
      dialogOpenGif: false,
      dialogDelete: false,
      dialogDeleteError: false,
      openedGif: '',
      isValid: true,
      errors: {
        file_upload_errors: [],
        internal_error: false,
        out_of_disc_space_error: false,
      },
      errorsDefault: {
        file_upload_errors: [],
        internal_error: false,
        out_of_disc_space_error: false,
      },
      loading: false,
      cue: {},
      cue_name: '',
      files: [],
      percentCompleted: 0,
    }),
    computed: {
      cueListHeaders() {
        return [
          { text: this.$t('cues_page.cue_list_headers.gif'), value: 'gif' },
          { text: this.$t('cues_page.cue_list_headers.filename'), value: 'filename' },
          { text: this.$t('cues_page.cue_list_headers.duration'), value: 'duration' },
          { text: this.$t('cues_page.cue_list_headers.universes_count'), value: 'uni_count' },
          { text: this.$t('cues_page.cue_list_headers.frame_count'), value: 'frame_count' },
          { text: this.$t('cues_page.cue_list_headers.use_count'), value: 'use_count' },
          { text: this.$t('cues_page.cue_list_headers.upload_date'), value: 'created' },
        ]
      },
      isOperator() {
        return this.$store.getters.isOperator
      },
      isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
      },
      uploadProgress() {
        return this.$store.state.uploadProgress
      },
    },
    methods:{
      normalizeCreatedTime(createdTime) {
        return createdTime ? moment(createdTime).format('LL') : ''
      },
      setRowStyle(item) {
        if (item.no_file) {
          return 'no-file'
        }

      },
      getGif(gif) {
        return `${this.$store.state.mediaPath}${gif}?a=${Math.random()}`
      },
      resetGif(gif) {
        this.opneGif = ""
        this.opneGif = gif
      },
      openGif (item) {
        this.dialogOpenGif = true
        this.openedGif = item.gif
      },
      refresh () {
        this.$store.dispatch('refreshCues')
      },
      close () {
        this.uploadCueDialog = false
        this.uploadGifDialog = false
        this.$nextTick(() => {
          this.files = []
          this.errors = Object.assign({}, this.errorsDefault)
        })
        this.$forceUpdate()
      },
      uploadCues () {
        this.loading = true
        let formData = new FormData()

        for (let file of this.files) {
          formData.append('files', file)
        }
        Api.uploadCueFiles(formData)
          .then(
            (resp) => {
              console.log(resp),
              this.close()
            }
          )
          .catch(error => {
            if (error.response.status == 500) {
              this.errors.internal_error = true
            } else if (error.response.status == 413) {
              this.errors.out_of_disc_space_error = true
            } else if (error.response.status == 400) {
              this.errors.file_upload_errors = error.response.data
            } else {
              console.log(error.response)
            }
          })
          .finally(() => {
            this.$store.dispatch('getCues'),
            this.loading = false
          })
      },
      uploadGifs () {
        this.loading = true
        let formData = new FormData()

        for (let file of this.files) {
          formData.append('files', file)
        }
        Api.uploadCueGifs(formData)
          .then(
            (resp) => {
              console.log(resp),
              this.close()
            }
          )
          .catch(error => {
            if (error.response.status == 500) {
              this.errors.internal_error = true
            } else if (error.response.status == 413) {
              this.errors.out_of_disc_space_error = true
            } else if (error.response.status == 400) {
              this.errors.file_upload_errors = error.response.data
            } else {
              console.log(error.response)
            }
          })
          .finally(() => {
            this.$store.dispatch('getCues'),
            this.loading = false
          })
      },
      deleteCueFile (cue) {
        this.cue = cue
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.cue_name = this.cue.filename
        Api.deleteCueFile(this.cue.id)
        .then(() => this.$store.dispatch('getCues'))
        .catch(error => {
          console.log(error.response.data)
          this.errors = error.response.data
          this.dialogDeleteError = true
        })
        this.closeDelete()
      },
      closeDeleteError () {
        this.dialogDeleteError = false
        this.$nextTick(() => {
          this.errors = []
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.cue = undefined
        })
      },
    },
    created() {
      if (this.$store.getters.isOperator && this.isLicenseValid) {
        this.cueListHeaders.push(
          { text: 'Actions', value: 'actions', sortable: false },
        )
      }
    },
    mounted() {
      this.$store.dispatch('getCues')
    },
  }
</script>

<style>
.no-file {
  background-color: red;
}
</style>